.login-container {
  height: 100vh;
  margin: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  .login {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: space-between !important;
    height: 350px;
    width: 400px;
    box-sizing: border-box;
    padding: 20px 30px;
  }
}
