.nav-absolute {
  position: fixed;
  background-color: #fff;

  .navbar-container {
    width: 80px;
    height: 100vh;
    padding: var(--mantine-spacing-md);
    display: flex;
    flex-direction: column;
    border-right: 1px solid
      light-dark(var(--mantine-color-gray-3), var(--mantine-color-dark-4));
    .logo {
      height: 40px;
      width: 40px;
    }
    .nav-main {
      flex: 1;
      margin-top: 50px;
    }
    .nav-link {
      width: 50px;
      height: 50px;
      margin-bottom: 20px;
      border-radius: var(--mantine-radius-md);
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
