.products-container {
}
.product-modal {
  .price-row {
    margin-bottom: 10px;
  }
  .image-container {
    height: 200px;
    width: 280px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    box-sizing: border-box;
    padding: 10px;
  }
}
