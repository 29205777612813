.single-order-container {
  .body {
    .item-row {
      display: flex;
      flex-direction: column;
      .value {
        margin: 4px 0 20px 0;
      }
    }
  }
}
