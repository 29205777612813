.single-product-container {
  .body {
    .item-row {
      .value {
        margin: 4px 0 20px 0;
      }
    }
    .image-container {
      height: 200px;
      width: 280px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      box-sizing: border-box;
      padding: 10px;
    }
  }
}
