@import url("./fonts.scss");
* {
  font-family: "Nunito Sans", sans-serif;
}
.flex-row {
  display: flex;
  flex-direction: row;
}
.flex-col {
  display: flex;
  flex-direction: column;
}
.align-center {
  align-items: center;
}
.justify-center {
  justify-content: center;
}
.justify-end {
  justify-content: flex-end;
}
.justify-between {
  justify-content: space-between;
}
.align-end {
  align-items: flex-end;
}
.pointer {
  cursor: pointer !important;
}
