.forgot-password-container {
  height: 100vh;
  .forgot {
    width: 340px;
    .title {
      font-size: 26px;
    }

    .controls {
      @media (max-width: var(--mantine-breakpoint-xs)) {
        flex-direction: column-reverse;
      }
    }

    .control {
      @media (max-width: var(--mantine-breakpoint-xs)) {
        width: 100%;
        text-align: center;
      }
    }
  }
}
